
/*
 * Gestion des point de vente dans la fiche produit
 * @author Aurélien Renault
 */
var Eresa = {

    boxName: 'e-resaBox',
    box: $('#e-resaBox'),
    content: $('#content_e-resaBox'),
    stores: {}, // La liste de tous les magasin ereservation
    storeSelected: {}, // Le magasin sélectionné
    places: {}, // Google Place
    storeHtml: '', // Le template des magasins
    step: 0, // Le template des magasins
    product: {
        product_id : '',
        color_id : '',
        size_id : ''
    }, // les info du produit
    nbKilometerMax: (typeof eresa_nb_km_max !== 'undefined') ? eresa_nb_km_max : 50, // Le nombre de kilometre maximum pour la recherche du magasin
    
    // Permet de charger tous les magasins en e-resa (etape 2). Ici pas de carte
    storeInit: function() {

        Mustache.parse(Eresa.storeHtml);

        var data = {
            'product_id' : Eresa.product.product_id,
            'color_id' : Eresa.product.color_id,
            'size_id' : Eresa.product.size_id,
            'version' : 2,
        };

        var self = this;
        $.ajax({
            type : 'post',
            url : path_relative_root + 'ajax_get_store_ereservation.php',
            data: data,
            success : function (boutique_bookinstore) {

                if (boutique_bookinstore) {

                    self.stores = $.parseJSON(boutique_bookinstore);

                    // Pour la restriction google
                    var countries = [];
                    var select = $('select[name="country"]', Eresa.box);
                    var store = '';
                    var length = self.stores.length;
                    // Construction de la liste des pays disponibles
                    for (var i = 0 ; i < length ; i++) {
                        store = self.stores[i];
                        var doublon = countries.find(function (val) {
                            return val === store.iso;
                        });
                        if (doublon === undefined) {
                            countries.push(store.iso);
                            // Pour la construction du select
                            var option = '<option value="' + store.id + '">' + store.country + '</option>';
                            select.append(option);
                        }
                    }

                    // Le trigger sur le select pour bien sélectionner le produit (car le select utilise un plugin)
                    select.trigger('change');

                    navigator.geolocation.getCurrentPosition(function (position) {
                        var pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        if (pos.lat>0 && pos.lng>0) {
                            self.storeOnPlaceChange(pos);
                        }
                    });

                    var options = {
                        types: ['geocode'],
                        style: google_map_style // dans le cas du chargement de la carte
                    };

                    if (countries && countries.length <= 5) {
                        options.componentRestrictions = {
                            country: countries
                        };
                    }

                    var eltAutoAdresse = document.getElementById('eresa_autoAddress');
                    // Initialisation de l'autocomplete
                    self.places = new google.maps.places.Autocomplete(eltAutoAdresse, options);

                    google.maps.event.addListener(self.places, 'place_changed', self.storeOnPlaceChange.bind(self));
                    google.maps.event.addDomListener(eltAutoAdresse, 'keydown', function(evt) {
                        // On bloque la touche enter
                        if (evt.keyCode === 13) {
                            evt.preventDefault();
                        }
                    });

                    // On "active" la nouvelle étape
                    $('#step' + Eresa.step).addClass('actif');
                } else {
                    alert(Translator.translate('no_store_ereservation'))
                }
            }
        });
    },
    /**
     * Event quand on change d'adresse
     */
    storeOnPlaceChange: function(auto_location) {

        var storeLocator = $('.store_locator');

        // Récupération de la latitude/longitude de la place trouvé pour le calcul de la distance avec tous les magasins
        if (typeof(auto_location) != 'undefined') {
            var latitude = auto_location.lat;
            var longitude = auto_location.lng;

        } else {
            var place = this.places.getPlace();
            var latitude = place.geometry.location.lat();
            var longitude = place.geometry.location.lng();
        }

        var distance = 0;

        storeLocator.removeClass('loaded');

        var storesOrdonnee = []; // La liste de tous les magasin ereservation ordonné par distance
        // On va parcourir chaque magasin trouvé pour calculer la distance entre le magasin et la position de l'adresse
        $.each(this.stores, function(index, store) {
            // Si la latitude exist
            if (store.lat != 0) {
                // Calcul de la distance avec formule haversine
                // Le calcul est le même que dans la fonction du BO en BDD)
                var radlon1 = (longitude * Math.PI) / 180;
                var radlon2 = (store.lng * Math.PI) / 180;
                var radlat1 = (latitude * Math.PI) / 180;
                var radlat2 = (store.lat * Math.PI) / 180;
                var dlo = (radlon2 - radlon1) / 2;
                var dla = (radlat2 - radlat1) / 2;
                var a = Math.sin(dla) * Math.sin(dla) + Math.cos(radlat2) * Math.sin(dlo) * Math.sin(dlo);
                distance = 6371 * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); // 6371 rayon de la terre
                store.distance = Math.round(distance * 100) / 100;

            }
            var length = storesOrdonnee.length;
            if (length > 0) {
                // Maintenant on va ordonner la liste des magasins au fur et à mesure
                for (var i = 0 ; i < length ; i++) {
                    // Si la valeur est plus petite que le premier element
                    if (store.distance < storesOrdonnee[i].distance) {
                        storesOrdonnee.splice(i, 0 , store);
                        break;
                    } else if (storesOrdonnee[i+1] !== undefined) { // si on a un élément suivant
                        // Si la distance du magasin est supérieur au courant et inférieur au suivant alors on a trouvé sa place
                        if (store.distance > storesOrdonnee[i].distance && store.distance < storesOrdonnee[i+1].distance) {
                            storesOrdonnee.splice(i+1, 0 , store);
                            break;
                        }
                    } else { // si plus d'élement suivant on met la valeur à la fin
                        storesOrdonnee.push(store);
                    }
                }
            } else {
                storesOrdonnee.push(store);
            }
        });

        // Maintenant qu'on a les magasin dans l'ordre de leur distance on va générer les blocs
        this.generateStoreBloc(storesOrdonnee);

        // On signale que tous les magasin ont été chargé
        storeLocator.addClass('loaded');
    },
    // Pour générer les bloc des magasins
    generateStoreBloc: function(stores) {

        var self = this;

        var storeLocator = $('.store_locator');

        // On vide le bloque
        storeLocator.html('');
        // Les magasins avec au moin 1 stock
        var nbStoreAvailable = 0;
        // Les magasins assez proche
        var nbStoreAvailableNear = 0;
        $.each(stores, function(index, store) {
            // On regarde si il y a un horaire de renseigner
            if (store.schedule !== '') {
                // On va mettre la class actif
                store.showSchedule = 'actif';
            } else {
                store.showSchedule = '';
            }
            store.showQuantity = '';
            store.S_article = '';

            if(store.quantity==null) {
                store.quantity = 0;
            }

            if (store.quantity < 5) {
                if (store.quantity > 1) {
                    // Juste pour mettre un s à articles
                    store.S_article = 's';
                }
                store.showQuantity = 'actif';
            }
            if (store.quantity==0) {
                store.showQuantity = 'no_stock';
                store.styleButton = 'display:none;';
                store.hide_text = 'display:none;';
                store.show_text = 'display:inline-block;';
            }
            if (store.quantity >= 1) {
                store.hide_text = 'display:inline-block;';
                store.show_text = 'display:none;';
            }
            // Si il n'y a pas assez de stock alors on n'affiche pas le magasin
            nbStoreAvailable ++;
            if (store.distance <= self.nbKilometerMax) {
                var html = Mustache.render(
                    self.storeHtml,
                    store
                );
                $(html).appendTo(storeLocator);
                nbStoreAvailableNear ++;
            }
        });

        var report = $('<div>', {class: 'js_store_report'});
        if (nbStoreAvailable === 0 || nbStoreAvailableNear === 0) {
            if (nbStoreAvailable === 0) {
                report.html(Translator.translate('store_not_available_quantity'));
            } else if (nbStoreAvailableNear === 0) {
                report.html(Translator.translate('store_not_available_near'));
            }
            $(report).appendTo(storeLocator);
        }

        // L'event pour afficher les horaires
        $('.js_have_horaire_store.actif', storeLocator).on('click', function(event) {
            event.preventDefault();
            var sheduleStore = $(this).next('.schedule_store');
            // Pour afficher les horaires du magasins
            if (!sheduleStore.hasClass('actif')) {
                sheduleStore.addClass('actif');
            } else {
                sheduleStore.removeClass('actif');
            }
        });
    },
    // Dans le cas où on change de couleur
    onChangeColor: function() {
        var color = $('input[name=itm_color]:checked', Eresa.box);
        var size = $('input[name=itm_size]:checked', Eresa.box);
        var productId = color.data('productid');
        var data = {
            'produit_id': productId,
            'couleur_id': color.val(),
            'taille_id': size.val(),
        };
        if (color.data('regroupement') === 1) {
            // Chargement du nouveau produit en cas de regroupement
            this.loadStepEresa(data, 1);
        } else {
            // Changement de l'image
            $('#js_product_bookinstore_img').attr('src', color.data('pic'))
        }
    },
    // Permet de récupérer toutes les informations du produits
    /**
     *
     * @param data
     * @param statut => le statut de chargement (0, 1) les chiffres sont les numéros d'étape (0 => init)
     */
    loadStepEresa: function(data, statut) {
        
        // Chargement
        $('.steps', Eresa.box).addClass('loading');
        $.ajax({
            url: path_relative_root + 'ajax_bookinstore_produit.php',
            type: 'POST',
            data: data,
            success: function(response){
                try {
                    response = $.parseJSON(response);
                } catch(e){
                    return;
                }

                if (response.success === 'ok'){

                    if (document.getElementById('lightbox_achat_express')) {
                        $('#lightbox_achat_express').remove();
                    }

                    if (Eresa.content.length === 0){
                        new Vue({components:{wEresaForm: wEresaForm}}).$mount('w-eresa-form');
                    }
                    var datas = response.datas;
                    var html = $(datas.html);

                    if (statut === 0) {
                        var formEresa = $('#form_eresa');
                        if (Eresa.content.length === 0){
                            Eresa.content = $('#content_e-resaBox');
                            Eresa.box = $('#e-resaBox');
                        }
                        Eresa.content.html(html);
                        // On va déplacer le formulaire de vue JS au bon endroit
                        $('#js_form_infos_bookinstore_vue').append(formEresa);

                        // Ouverture de la ligthbox
                        openMultiShad(Eresa.boxName);
                    } else if (statut === 1) {
                        var step1 = $('#step1', html).html();

                        $('#step1', Eresa.content).html(step1);
                    }
                    // Chargement
                    $('.steps', Eresa.box).removeClass('loading');

                } else {
                    alert(response.error.msg);
                }
            },
            complete: function(){
                $('html, body').animate({ scrollTop: 0 }, $(document).scrollTop() * 0.8);

                // enveloper taille et couleur dans le div "size-color-wrap"
                // pour pouvoir ajouter le css "display:flex"
                $('#e-resaBox.lightbox #step1 .product_info .ligne_form').wrapAll( "<div class='size-color-wrap' />");

                // Initialisation du template des stores
                if (Eresa.storeHtml === '') {
                    Eresa.storeHtml = $('.store_locator .store')[0].outerHTML;
                }

                if (!Eresa.checkProduct()) {
                    btnLoaderUnloading($("#step1 .form_submit").find(':first'));
                    return false;
                }
                Eresa.storeInit();
            }
        });
    },
    // Pour vérifier les information du produit
    checkProduct: function() {
        var color = (0, Eresa.box);
        var size = (0, Eresa.box);
        var idProduit = document.getElementById('produit_principal').value;
        $('#TailleIdBookInStore').val(size.val());
        $('#CouleurIdBookInStore').val(color.val());
        $('#ProduitIdBookInStore').val(idProduit);

        Eresa.product.product_id = idProduit;
        Eresa.product.color_id = 0;
        Eresa.product.size_id = 0;
        // On va attribuer les valeurs pour le récapitulatif (taille et couleur)
        $('#js_color').html(color.data('name'));
        $('#js_size').html(size.data('name'));
        return true;
    },
    // Pour vérifier les informations du magasin
    checkStore: function(btn) {
        var self = this;
        $('#StoreIdBookInStore').val($(btn).data('magasinid'));
        // Enregistrement des informations du magasin (pour l'étape récapitulatif)
        // On va rechercher le magasin selectionné dans la liste
        $.each(this.stores, function(index, store) {
            if (store.id === $(btn).data('magasinid')) {
                self.storeSelected = store;
            }
        });
        return true;
    },
    next: function() {
        $('.step_num[data-step="2"]').on('click', function() {
            Eresa.step = 2;
            $('.step').removeClass('actif');
            $('.step_num').removeClass('actif');
            $('#step' + Eresa.step).addClass('actif');
            // ONGLET : On "active" l'étape 2
            $('.step_num[data-step=' + Eresa.step + ']').addClass('actif');
        });

        $('.step_num[data-step="2"]').css({
            cursor: 'pointer'
        });
        if(Eresa.step > 0) {
            // cache l'element "popup_desc" quand c'est pas etape 1
            // $('#content_e-resaBox .popup_desc').css('display', 'none');
        }

        // Verification que la couleur et la taille ont bien été choisie
        if(Eresa.step === 2) { // Choix du magasin
            if (!Eresa.checkStore(this)) {
                return false;
            }
        } else if(Eresa.step === 3) { 
            // Formulaire
            // La vérification du formulaire est faite dans le vuejs (w-pdv-form)
        }

        // On attend d'avoir la reponse car la prochaine étape ne doit pas encore s'afficher
        if (Eresa.step!=3) {
            // On "desactive" l'ancienne étape
            $('#step' + Eresa.step).removeClass('actif');

            // ONGLET : On "desactive" l'ancienne étape
            $('.step_num[data-step='  + Eresa.step + ']').removeClass('actif');
        }

        Eresa.step++;

        if (Eresa.step!=4) {
            // ONGLET : On "active" la nouvelle étape étape
            $('.step_num[data-step='  + Eresa.step + ']').addClass('actif');
        }

        // Au chargement de la 2eme étape on charge les magasin
        if (Eresa.step === 4) { // validation du choix et envoi des informations
            var form = $('#form_eresa').serialize();
            $("#error_limit_quota").hide();
            $(".w-submit-button").hide();

            // Active la fonction displayLoaderBtn si elle est presente
            if (typeof displayLoaderBtn === "function") {
                displayLoaderBtn();
            }

            $.ajax({
                url: path_relative_root + 'ajax_bookinstore_client.php',
                type: 'POST',
                data: form,
                dataType: 'json',
                success: function (response){

                    if (response.success) {
                        // On "desactive" l'ancienne étape
                        $('#step' + (Eresa.step-1)).removeClass('actif');

                        // ONGLET : On "desactive" l'ancienne étape
                        $('.step_num[data-step='  + (Eresa.step-1) + ']').removeClass('actif');

                        // ONGLET : On "active" la nouvelle étape étape
                        $('.step_num[data-step='  + Eresa.step + ']').addClass('actif');

                        $('#step' + Eresa.step + ' .confirm').css('display', 'block');
                        $('#step' + Eresa.step + ' .confirm_info').css('display', 'none');

                        // copier texte message confirm dans l'element ".popup_desc"
                        $('#content_e-resaBox .popup_desc').html($('#step' + Eresa.step + ' .confirm_info').html());
                        $('#content_e-resaBox .popup_desc').css('display', 'block');
                    } else {
                        if (response.type_error=='limit_exceeded') {
                            Eresa.step--;
                            $("#error_limit_quota").show();
                            $("#error_limit_quota small").html(response.error_msg);
                        } else {
                            $('#step' + Eresa.step + ' .confirm').css('display', 'none');
                            $('#step' + Eresa.step + ' .error').html(response.error_msg).css('display', 'block');
                        }
                    }

                    $(".w-submit-button").show();

                    // On va attribuer les valeurs pour le récapitulatif (taille et couleur)
                    $('#js_color').html(response.product.color.name);
                    $('#js_size').html(response.product.size.name);

                    // Attribution des information du magasin
                    var html = Mustache.render(
                        Eresa.storeHtml,
                        Eresa.storeSelected
                    );

                    $('#js_eresa_store_choice').html(html);

                    // L'event pour afficher les horaires
                    $('.js_have_horaire_store.actif', $('#js_eresa_store_choice')).on('click', function(event) {
                        event.preventDefault();
                        var sheduleStore = $(this).next('.schedule_store');
                        // Pour afficher les horaires du magasins
                        if (!sheduleStore.hasClass('actif')) {
                            sheduleStore.addClass('actif');
                        } else {
                            sheduleStore.removeClass('actif');
                        }
                    });

                    // On "active" la nouvelle étape
                    $('#step' + Eresa.step).addClass('actif');
                    $(".content_e-resaBox").addClass('step4_actif');

                }
            });
        } else {
            // On "active" la nouvelle étape
            $('#step' + Eresa.step).addClass('actif');
        }
    },
    // Montre la lightbox des points de ventes
    show: function() {
        var produitId = $('#produit_id').val();

        var couleurId = $('#couleurProd').val();
        var tailleId = $('#tailleProd').val();

        var data = {
            'produit_id': produitId,
            'couleur_id': couleurId,
            'taille_id': tailleId,
        };
        // Chargement du produit dans la lightbox
        Eresa.loadStepEresa(data, 0);

        Eresa.step = 1;

        // On ne laisse plus le choix des tailles / couleur deja fait avant
        Eresa.next();

    },
    // Cache la lightbox des points de ventes
    close: function() {

        closeMultiShad(Eresa.boxName);
        setTimeout(function() {Eresa.RAZ()}, 1000);
    },

    // Remet à 0 les step
    RAZ: function() {
        // On revient à l'étape 1
        Eresa.step = 1;
        // On désactive tout
        $('.step').removeClass('actif');
        $('.step_num').removeClass('actif');
        // On active l'étape 1
        $('#step' + Eresa.step).addClass('actif');
        // ONGLET : On "active" l'étape 1
        $('.step_num[data-step='  + Eresa.step + ']').addClass('actif');
    },

    //localisation de la position
    geolocation: function() {
        navigator.geolocation.getCurrentPosition(function (position) {
            var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            if (pos.lat > 0 && pos.lng > 0) {
                Eresa.storeOnPlaceChange(pos);
            }
        });
    }
};